import React from 'react'
import Layout from '../components/layout'
import PriceContent from '../components/Pricings/PriceContent'

const PricingPage = () => (
  <Layout>
    <PriceContent />
  </Layout>
)

export default PricingPage
