import { StripeProductEnum, StripeProductType, SubscriptionProductsData } from '@curvo/apollo'
import { Container, NormalText } from '@curvo/common-ui'
import LoadingIndicator from '@curvo/common-ui/dist/components/Loading/Spinner'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import styled from 'styled-components'
import Client from '../../config/Apollo'
import { MAX_MEDIUM_SCREEN_WIDTH, MAX_SMALL_IPAD_SCREEN_WIDTH, MAX_SMALL_SCREEN_WIDTH } from '../GlobalStyles'
import Plan from './Plan'
import PlanStatic from './PlanStatic'
import { PlansContainer } from './Pricings'

const TitleText = styled(NormalText)`
  margin-bottom: 34px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 20px;
  }
`
const BodyText = styled(NormalText)`
  max-width: 920px;
  margin-bottom: 40px;
  text-align: left;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    max-width: 746px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    max-width: 622px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    max-width: 100%;
    text-align: center;
  }
`

const PriceContent: React.FC = () => {
  const getProductByType = (data: StripeProductType[], type: StripeProductEnum) =>
    data.find(item => item.type === type && item.active === true)

  return (
    <Container flexDirection="column">
      <TitleText className="section-title">Pricings</TitleText>
      <BodyText>
        An Orthopedic Networks News by Curvo subscription gives you access to the gold standard in orthopedic content,
        classification, and categorization. We have laid out the pricing by subscription type so you can sign up for the
        one that best fits your business needs. Choose from a Newsletter only subscription, a Find-A-Part only
        subscription, or both.If you are looking to add multiple users, contact us for enterprise pricing.
      </BodyText>
      <PlansContainer>
        <ApolloProvider client={Client.getClient()}>
          <SubscriptionProductsData>
            {({ data, loading }) => {
              if (!data || loading) {
                return <LoadingIndicator center />
              }

              const newsletter = getProductByType(data.subscriptionProducts.data, StripeProductEnum.Newsletter)
              const findAPart = getProductByType(data.subscriptionProducts.data, StripeProductEnum.FindAPart)

              const newsletterPlans =
                newsletter && newsletter.plans
                  ? newsletter.plans.filter(item => item.id.includes('public-') && item.active === true)
                  : []

              let findAPartPlans =
                findAPart && findAPart.plans
                  ? findAPart.plans.filter(item => item.id.includes('public-') && item.active === true)
                  : []

              if (findAPartPlans.length) {
                findAPartPlans = [findAPartPlans[0]]
              }
              const plansList = [...newsletterPlans, ...findAPartPlans]

              return (
                <React.Fragment>
                  {plansList.map(plan => (
                    <React.Fragment key={`news-${plan.id}`}>
                      <Plan data={plan} spacing={'1.5rem'} />
                    </React.Fragment>
                  ))}
                  <PlanStatic spacing={'0rem'} />
                </React.Fragment>
              )
            }}
          </SubscriptionProductsData>
        </ApolloProvider>
      </PlansContainer>
    </Container>
  )
}

export default PriceContent
